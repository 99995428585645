import React from 'react';
import {
  Text,
  SitecoreContext,
  withSitecoreContext,
  ImageField,
  LinkFieldValue,
  Field,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import { ResponsiveImage, ResponsiveImageProps } from '../ResponsiveImage';
import { SearchField, SearchFieldProps } from '../SearchField';
import './index.scss';
import CoveoSearchBox from '../Coveo/CoveoSearchBox';

// Params added to match new SearchField props requirement
type HeroBannerWithSearchProps = {
  fields: {
    'Title Text': Field<string>;
    'Additional Text': Field<string>;
    'Search Field Label': Field<string>;
    'Desktop Image': ImageField;
    'Mobile Image': ImageField;
    'Tablet Image': ImageField;
    PlaceholderText: Field<string>;
    ResultsPage: Field<LinkFieldValue>;
    DisplaySearchedQuery: Field<boolean>;
    IsMobileHeaderSearch: Field<boolean>;
    IsPageSearch: Field<boolean>;
    CancelText: Field<string>;
    'Use Coveo Search': Field<boolean>;
  };
  params: {
    SearchFieldSize: Field<String>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const HeroBannerWithSearch = ({
  fields,
  params,
  sitecoreContext,
}: HeroBannerWithSearchProps): JSX.Element => {
  if (fields) {
    let imageObj: ResponsiveImageProps = {
      responsiveFields: {
        MobileImageSrc: fields['Mobile Image'],
        TabletImageSrc: fields['Tablet Image'],
        DesktopImageSrc: fields['Desktop Image'],
      },
      sitecoreContext: sitecoreContext,
    };
    let searchObj: SearchFieldProps = {
      fields: {
        PlaceholderText: fields.PlaceholderText,
        ResultsPage: fields.ResultsPage,
        DisplaySearchedQuery: fields.DisplaySearchedQuery,
        IsMobileHeaderSearch: fields.IsMobileHeaderSearch,
        IsPageSearch: fields.IsPageSearch,
        CancelText: fields.CancelText,
        'Use Coveo Search': fields['Use Coveo Search'],
      },
      params: {
        SearchFieldSize: params?.SearchFieldSize,
      },
      sitecoreContext: sitecoreContext,
    };
    return (
      <div className="hero-banner-with-search">
        {sitecoreContext.pageEditing && (
          <>
            <p>HeroBannerWithSearch Component Edit Mode</p>
          </>
        )}
        {
          <>
            <div className="text-column">
              <div>
                <Text tag="h1" className="title" field={fields['Title Text']} />
              </div>
              <RichText className="additional-text" field={fields['Additional Text']} />
              <div className="search-field-container">
                <div className="search-border-spacer">
                  <Text tag="span" field={fields['Search Field Label']} />
                  <div className="component-container">
                    <CoveoSearchBox fields={searchObj.fields}></CoveoSearchBox>
                  </div>
                </div>
              </div>
            </div>
            <div className="image-container">
              <ResponsiveImage
                responsiveFields={imageObj.responsiveFields}
                sitecoreContext={imageObj.sitecoreContext}
              />
            </div>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(HeroBannerWithSearch);

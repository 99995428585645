import React from 'react';
import { DidYouMean as HeadlessDidYouMean } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';

interface DidYouMeanProps {
  controller: HeadlessDidYouMean;
}

export const DidYouMean: FunctionComponent<DidYouMeanProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  if (!state.hasQueryCorrection) {
    return <></>;
  }

  if (state.wasAutomaticallyCorrected) {
    return (
      <div className="did-you-mean-text">
        No results for <b>{state.queryCorrection.wordCorrections[0].originalWord}</b>. Search was
        automatically corrected to <b>{state.wasCorrectedTo}</b>.
      </div>
    );
  }

  return (
    <>
      <span>Did you mean? </span>
      <a onClick={() => controller.applyCorrection()}>{state.queryCorrection.correctedQuery}</a>
    </>
  );
};

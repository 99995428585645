import React, { useState, useCallback, useEffect } from 'react';
import {
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { AppBar, Toolbar, useScrollTrigger, Slide } from '@material-ui/core';

import './index.scss';

interface Props {
  children: React.ReactElement;
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
}

function HideOnScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
export default function Header(props: Props) {
  return (
    <>
      <div id="emergeny-alert-main" className="emergency-alert-desktop">
        <Placeholder name="emergency-alert" rendering={props.rendering} />
      </div>
      <div className="header">
        <nav aria-label="Primary Navigation">
          {/* Start Utility Navigation Container */}
          <div className="grow utility-navigation">
            <div className="phone-number">
              <Placeholder name="desktop_utility_navigation_phone" rendering={props.rendering} />
            </div>

            <div className="utility-inner-wrapper">
              <div className="utility-nav-items">
                <Placeholder name="desktop_utility_navigation" rendering={props.rendering} />
              </div>
              <div className="headerSearch">
                <Placeholder name="search_container" rendering={props.rendering} />
              </div>
            </div>
          </div>
          {/* /END Utility Navigation Container */}
          <div className="grow">
            <div className="nav-height">
              <HideOnScroll {...props}>
                <AppBar className="appbar">
                  {/* Emergency Alert Mobile Display */}

                  <Placeholder name="emergency-alert" rendering={props.rendering} />

                  <Toolbar disableGutters className="toolbar">
                    <div className="logo-wrapper">
                      <Placeholder name="logo_container" rendering={props.rendering} />
                    </div>
                    <div className="section-desktop">
                      {/* Desktop Navigation Here*/}
                      <div className="desktop-nav-items">
                        <Placeholder name="desktop_main_navigation" rendering={props.rendering} />
                      </div>
                      {/* / END Desktop Navigation */}
                    </div>
                    <div className="section-mobile">
                      {/* Mobile Search Section */}
                      <div className="mobile-header-search-wrapper">
                        <Placeholder name="mobile_search_container" rendering={props.rendering} />
                      </div>
                      {/* /END Mobile Search Section */}
                      {/* Mobile Navigation */}
                      <div className="mobile-header-navigation-wrapper">
                        <Placeholder
                          name="mobile_navigation_container"
                          rendering={props.rendering}
                        />
                      </div>
                      {/* END Mobile Navigation */}
                    </div>
                  </Toolbar>
                </AppBar>
              </HideOnScroll>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

import React from 'react';
import './index.scss';
// import { withTranslation } from 'react-i18next';

const tabPosition = 0;

const SkipToContent = (): JSX.Element => {
  return (
    <div className="skiptocontent">
      <a href="#main-content">Skip To Content</a>
    </div>
  );
};

export default SkipToContent;

// const SkipToContent = (props: any): JSX.Element => {
//   return (
//     <div className="skiptocontent">
//       <Link to="main-content" tabIndex={tabPosition} spy={true} smooth={true}>{props.t('skip_to_content')}</Link>
//     </div>
//   );
// };

// export default withTranslation()(SkipToContent);

import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Field,
  SitecoreContext,
  withSitecoreContext,
  LinkFieldValue,
} from '@sitecore-jss/sitecore-jss-react';

import './index.scss';

export type SearchFieldProps = {
  fields: {
    PlaceholderText: Field<string>;
    ResultsPage: Field<LinkFieldValue>;
    DisplaySearchedQuery: Field<boolean>;
    IsMobileHeaderSearch: Field<boolean>;
    IsPageSearch: Field<boolean>;
    CancelText: Field<string>;
    'Use Coveo Search': Field<boolean>;
  };
  params: {
    SearchFieldSize: Field<String>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

export const SearchField = ({ fields, params, sitecoreContext }: SearchFieldProps): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFocus, setSearchFocus] = useState(false);
  const search = useLocation().search;
  const queryText = new URLSearchParams(search).get('q');
  const searchFieldSize = params?.SearchFieldSize;
  const searchAreaRef = useRef(null);
  const queryAttribute = fields?.['Use Coveo Search']?.value ? '#q=' : '?q=';

  const handleChange = (e: { target: { name: any; value: string } }) => {
    setSearchQuery(e.target.value);
  };

  const handleSubmit = (event: any) => {
    let query = fields.ResultsPage?.value?.href + queryAttribute + searchQuery;
    window.open(query, '_self');
    event.preventDefault();
  };

  const handleFocus = (event: any) => {
    if (fields.IsMobileHeaderSearch.value) {
      event.preventDefault();
      setSearchFocus(true);
    }
  };
  const handleBlur = (event: any) => {
    if (fields.IsMobileHeaderSearch.value) {
      event.preventDefault();
      setSearchFocus(false);
      setSearchQuery('');
    }
  };
  const handleReset = (event: any) => {
    setSearchQuery('');
  };
  const handleClickOutside = (event: any) => {
    if (fields.IsMobileHeaderSearch.value) {
      const path = event.path || (event.composedPath && event.composedPath());
      if (!path.includes(searchAreaRef.current)) {
        setSearchFocus(false);
        setSearchQuery('');
      }
    }
  };
  useEffect(() => {
    if (queryText && fields.DisplaySearchedQuery.value) {
      setSearchQuery(queryText);
    }
  }, [queryText]);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // TODO: Encode and Decode url params as they are passed around.
  // https://stackoverflow.com/questions/2794137/sanitizing-user-input-before-adding-it-to-the-dom-in-javascript
  // - Second answer in this article
  if (fields) {
    return (
      <>
        <div
          className={`site-search${searchFocus ? ' full-width-mobile-search' : ''}${
            fields.IsPageSearch.value ? ' site-search-border' : ''
          }`}
          ref={searchAreaRef}
        >
          {sitecoreContext.pageEditing && (
            <>
              <p>SearchField Component Edit Mode</p>
            </>
          )}
          <div className="full-width-helper">
            <div
              className={fields.IsPageSearch.value ? 'site-search-frame ' + searchFieldSize : ''}
            >
              <div className="site-search-form-wrapper">
                <form
                  name="globalSearch"
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="search-field-wrapper">
                    <input
                      type="text"
                      name="searchField"
                      className={`site-search-field${
                        fields.IsMobileHeaderSearch.value ? ' isMobileHeaderSearch' : ''
                      }${fields.DisplaySearchedQuery.value ? ' displaySearchedQuery' : ''}`}
                      placeholder={fields.PlaceholderText?.value}
                      aria-label="search"
                      onChange={handleChange}
                      value={searchQuery}
                      autoComplete="off"
                      onFocus={handleFocus}
                    />
                  </div>
                  <div className="site-search-reset-button">
                    {searchQuery && <input type="reset" value="reset" onClick={handleReset} />}
                  </div>
                  <div className="site-search-search-button">
                    <input type="submit" value="Search" />
                  </div>
                </form>
              </div>
              {searchFocus && fields.IsMobileHeaderSearch.value && (
                <div className="close-mobile-search">
                  <input type="" value="Cancel" onClick={handleBlur} />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(SearchField);

import React, { useEffect, useRef } from 'react';
import { SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Pagination } from '@material-ui/lab';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import InnerHTML from 'dangerously-set-html-content';
import { applyPhoneLinks } from './../../utils/helper';
import './index.scss';

interface DataSource {
  'Results Root Item': {
    value: string;
  };
  'Root of Site': {
    value: string;
  };
  'Error Message': {
    value: string;
  };
  'No Results Message': {
    value: string;
  };
  'Loading Message': {
    value: string;
  };
}

interface TargetItems {
  location: {
    value: string;
  };
}

interface ConsultantsResult {
  displayName: string;
  title: string;
  firstName: string;
  lastName: string;
  specialty: string;
  phone1: string;
  phone2: string;
  phone3: string;
  link: string;
  item: {
    image: {
      src: string;
      alt: string;
    };
    schedules: {
      targetItems: [TargetItems];
    };
  };
}

interface myConsultantResults {
  results: {
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: number;
      endCursor: number;
    };
    items: [ConsultantsResult];
  };
}

interface SearchVariables {
  consultantsUserQuery: string;
}

type ConsultantListingProps = {
  rendering: {
    fields: {
      data: {
        datasource: DataSource;
      };
    };
  };
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    custom: {
      targetHostName: string;
    };
  };
  consultantResultsQuery: {
    consultantResults: myConsultantResults;
    variables: SearchVariables;
    loading: boolean;
    error: {
      message: string;
      stack: string;
    };
  };
  fields: DataSource;
};

// CONSULTANT PROFILE LAYER
const ConsultantProfileSummary = (consultant: ConsultantsResult): JSX.Element => {
  return (
    <Grid item xs={12} md={6} className="consultant-profile-summary">
      {/* Title & Full Name */}
      <h2 className="display-name">{consultant.displayName}</h2>
      {/* Flex container for photo and details container */}
      <div className="consultant-profile-wrapper">
        {/* Image */}
        {consultant.item.image.src ? (
          <div className="image-container">
            <img src={consultant.item.image.src} alt={consultant.item.image.alt} />
          </div>
        ) : (
          <></>
        )}
        {/* Container for specialty, location, and contact */}
        <div className="details-wrapper">
          {/* Specialty */}
          {consultant.specialty ? <h3>{consultant.specialty}</h3> : <></>}
          {/* Location */}
          {consultant.item.schedules.targetItems.length ? (
            consultant.item.schedules.targetItems.map((item, index) => (
              <span className="consultant-location" key={index}>
                <strong>{item.location.value}</strong>
              </span>
            ))
          ) : (
            <></>
          )}

          {/* Phone */}
          <ul className="phone-list">
            {consultant.phone1 ? <li>Tel: {consultant.phone1}</li> : <></>}
            {consultant.phone2 ? <li>Tel: {consultant.phone2}</li> : <></>}
            {consultant.phone3 ? <li>Tel: {consultant.phone3}</li> : <></>}
          </ul>
          {/* Button */}
          <div>
            <a href={consultant.link}>
              <Button color="secondary" variant="contained">
                View Profile
              </Button>
            </a>
          </div>
        </div>
      </div>
    </Grid>
  );
};

// CONSULTANT LIST LAYER
const ConsultantListing = ({
  consultantResultsQuery,
  sitecoreContext,
  fields,
}: ConsultantListingProps): JSX.Element => {
  const { consultantResults, loading, variables, error } = consultantResultsQuery;

  // Validation & Error Handling
  if (!consultantResultsQuery || !fields) {
    if (sitecoreContext.pageEditing) {
      return <p>Please setup the Datasource.</p>;
    } else {
      return (
        <>
          <br />
        </>
      );
    }
  }

  if (loading || typeof window === 'undefined') {
    return <InnerHTML html={fields['Loading Message'].value} />;
  }

  if (error) {
    return (
      <>
        <InnerHTML html={fields['Error Message'].value} />
        <>
          <div style={{ display: 'none' }}>
            <h3>{error.message}</h3>
            <p>{error.stack}</p>
          </div>
          <br />
          <br />
          <br />
        </>
      </>
    );
  }

  if (consultantResults.results.totalCount === 0) {
    return <InnerHTML html={fields['No Results Message'].value} />;
  }

  // Pagination Logic
  let pages = 0;
  let pageSize = 10;
  let currentPage = 1;
  if (consultantResults.results.totalCount > currentPage) {
    pages = Math.ceil(consultantResults.results.totalCount / pageSize);
    currentPage = Math.ceil(consultantResults.results.pageInfo.endCursor / pageSize);
  } else {
    pages = 1;
  }

  let fullpath = sitecoreContext.custom.targetHostName;
  fullpath = fullpath.replace('http://', 'https://');
  let pathParts = fullpath.split('/');

  let hostname = fullpath.substring(0, fullpath.search(pathParts[pathParts.length - 1]));
  let path = '/' + pathParts[pathParts.length - 1];

  // TODO modify this implementation to an in-page load rather than a reload
  const handlePageChange = (event: any, value: any) => {
    if (typeof window !== 'undefined') {
      let query =
        fullpath +
        '?q=' +
        consultantResultsQuery.variables.consultantsUserQuery +
        '&si=' +
        (value - 1) * pageSize;
      window.open(query, '_self');
      event.preventDefault();
    }
  };

  let basePathLength = fields['Root of Site']?.value?.length;
  function clearLinks(item: ConsultantsResult, index: number, array: [ConsultantsResult]) {
    array[index].link = item.link.substring(basePathLength + 1);
  }
  function trimLinks(item: ConsultantsResult, index: number, array: [ConsultantsResult]) {
    let trimmedPath = array[index].link.split('/');
    trimmedPath = trimmedPath.slice(0, trimmedPath.length - 2);
    array[index].link = trimmedPath.join('/').replace(/\s+/g, '-');
  }
  consultantResults.results.items.forEach(clearLinks, 0);
  consultantResults.results.items.forEach(trimLinks);

  // click to call
  const elemRef: any = useRef<HTMLDivElement>(null);
  useEffect(() => {
    applyPhoneLinks(elemRef?.current, sitecoreContext.pageEditing);
  }, []);

  return (
    <div className="consultant-listing" ref={elemRef}>
      {consultantResults && (
        <>
          <div className="consultant-summary">
            Showing results{' '}
            <strong>
              {consultantResults.results.pageInfo.startCursor}-
              {consultantResults.results.pageInfo.endCursor}
            </strong>{' '}
            of <strong>{consultantResults.results.totalCount}</strong>
            {variables.consultantsUserQuery
              ? ` results for "${variables.consultantsUserQuery}"`
              : ' results'}
          </div>

          <Grid container alignItems="stretch">
            {consultantResults.results.items.map((consultant: ConsultantsResult) =>
              ConsultantProfileSummary(consultant)
            )}
          </Grid>

          <Pagination
            count={pages}
            page={currentPage}
            color="secondary"
            shape="rounded"
            onChange={handlePageChange}
            className="search-result-pagination"
          />
        </>
      )}
    </div>
  );
};

function getUserQuery() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let query = params?.get('q') || '';
  return query;
}

function getStartItem() {
  let uri = typeof window !== 'undefined' ? window.location.search : '?q=&si=0';
  let params = new URLSearchParams(uri.substring(1));
  let start = params?.get('si') || '0';
  return start;
}

function getRootItem(props: any) {
  let rootItemPath = props.fields['Results Root Item']?.value;
  if (rootItemPath) {
    return rootItemPath;
  } else {
    return '/sitecore/content/UPMC/Ireland/Home/Consultants';
  }
}

const ConsultantResultsQuery = gqlLoader('./query.graphql');

const MakeConsultantsQuery = (props: any) => {
  const WrappedConsultantResults = GraphQLData(ConsultantResultsQuery, {
    name: 'consultantResultsQuery',
    refetch: { getUserQuery },
    options: {
      variables: {
        consultantsUserQuery: getUserQuery(),
        consultantsRootItem: getRootItem(props),
        consultantsStartIndex: getStartItem(),
      },
      fetchPolicy: 'no-cache',
    },
  })(ConsultantListing);
  return <WrappedConsultantResults {...props} />;
};

export default MakeConsultantsQuery;

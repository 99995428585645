import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Pager as HeadlessPager } from '@coveo/headless';
import { Pagination } from '@material-ui/lab';

interface PagerProps {
  controller: HeadlessPager;
}

export const Pager: FunctionComponent<PagerProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);
  var windowSize = null;
  if (typeof window !== 'undefined') {
    windowSize = useRef([window.innerWidth, window.innerHeight]);
  }
  var siblingCountValue = 1;
  // changes pagination for mobile 600px is small screen standard
  if (windowSize && windowSize.current[0] < 600) {
    siblingCountValue = 0;
  }

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  const handleChange = (event: any, value: number) => {
    controller.selectPage(value);
    window.scrollTo(0, 0);
  };

  return (
    <>
      {state.maxPage > 0 && (
        <nav className="pager">
          <Pagination
            count={state.maxPage}
            siblingCount={siblingCountValue}
            page={state.currentPage}
            color="secondary"
            shape="rounded"
            onChange={handleChange}
            className="search-result-pagination"
          />
        </nav>
      )}
    </>
  );
};

import React from 'react';
import { useEffect, useState, FunctionComponent, PropsWithChildren } from 'react';
import Tab from '@material-ui/core/Tab';

interface TabProps {
  controller: any;
  title: string;
  value: string;
  pagerController: any;
}

export const CoveoTab: FunctionComponent<TabProps> = (props) => {
  const { controller, pagerController } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);
  const handleChange = (event: any, newValue: any) => {
    pagerController.selectPage(1);
    controller.select();
  };
  return (
    <Tab
      label={props.title}
      onChange={handleChange}
      aria-label={props.title}
      className={state.isActive ? 'active' : 'inactive'}
      value={props.value}
    ></Tab>
  );
};

import { createTheme } from '@material-ui/core/styles';

const IrelandTheme = createTheme({
  // breakpoints:{
  //   values:{
  //     xl: '1920',
  //   },
  // },
  palette: {
    primary: {
      light: '#904199',
      main: '#4d104a',
      dark: '#2b0622',
      contrastText: '#fff'
    },
    secondary: {
      light: '#228848',
      main: '#228747',
      dark: '#1C723C',
      contrastText: '#fff',
    },
    grey: {
      400: '#EFEFEF',
      500: '#DBD9D6',
      600: '#BDBBBB',
      700: '#9A9A9B',
      800: '#666D70',
      900: '#363533',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: '"Catamaran", sans-serif',
    fontSize: 18,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'inherit',
        borderRadius: 25,
        padding: '12px 32px',
        textDecoration: 'none',
      },
      primary: {
        textDecoration: 'none',
      },
      secondary: {
        backgroundColor: '#228747',
      },
    },
  },
});

export default IrelandTheme;

import React from 'react';
import { buildInteractiveResult, Result, SearchEngine } from '@coveo/headless';
import { FunctionComponent, useEffect } from 'react';
import { GetSearchUrl } from './utils';

interface ResultLinkProps {
  result: Result;
  hostName: string | undefined;
  useUrlAsLinkText: boolean;
  engine: SearchEngine;
  getUrl?: any;
}

export const ResultLink: FunctionComponent<ResultLinkProps> = (props) => {
  const controller = buildInteractiveResult(props.engine, {
    options: { result: props.result },
  });

  useEffect(() => () => controller.cancelPendingSelect());

  const url = props.getUrl ? props.getUrl(props) : GetSearchUrl(props.result, false);

  return (
    <a
      href={url}
      onClick={() => controller.select()}
      onContextMenu={() => controller.select()}
      onMouseDown={() => controller.select()}
      onMouseUp={() => controller.select()}
      onTouchStart={() => controller.beginDelayedSelect()}
      onTouchEnd={() => controller.cancelPendingSelect()}
    >
      {props.useUrlAsLinkText && GetSearchUrl(props.result, false)}
      {!props.useUrlAsLinkText && props.children}
    </a>
  );
};

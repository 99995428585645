import { buildUrlManager } from '@coveo/headless';

let fragment = '';

function UrlManager(headlessEngine: any) {
  if (typeof window !== 'undefined') {
    fragment = window.location.hash.slice(1);
  }
  const urlManager = buildUrlManager(headlessEngine, {
    initialState: { fragment },
  });

  urlManager.subscribe(() => {
    const hash = `#${urlManager.state.fragment}`;
    history.pushState(null, document.title, hash);
  });

  if (typeof window !== 'undefined') {
    window.addEventListener('hashchange', () => {
      const fragment = window.location.hash.slice(1);
      urlManager.synchronize(fragment);
    });
  }
}

function UrlSplit(targetHostName: string) {
  let fullpath = targetHostName;
  let pathParts = fullpath?.split('/');
  return pathParts;
}

function RemoveTrailingSlashes(url: string) {
  let trimmedUrl = url;
  if (trimmedUrl.endsWith('/')) {
    trimmedUrl = trimmedUrl.slice(0, trimmedUrl.length - 1);
    trimmedUrl = RemoveTrailingSlashes(trimmedUrl);
  }

  return trimmedUrl;
}

function GetSearchUrl(result: any, isLocalized: boolean) {
  let finalUrl = result?.clickUri?.replace('http://', 'https://');
  if (!isLocalized) {
    // remove language
    let fullSet = finalUrl.split('/');
    let final = fullSet[0] + '/' + fullSet[1] + '/' + fullSet[2] + '/';
    for (var i = 4; i < fullSet.length; i++) {
      final += fullSet[i] + '/';
    }
    finalUrl = final;
  }
  // Remove trailing slash
  finalUrl = RemoveTrailingSlashes(finalUrl);
  return finalUrl;
}

function GetRelativeUrl(result: any) {
  var clickableUrl = result?.clickUri;
  var clickableUrlSplit = UrlSplit(clickableUrl);
  var domain = clickableUrlSplit[0] + '//' + clickableUrlSplit[2] + '/';
  let newReplacement = clickableUrl.replace(domain, '');
  let finalUrl = '/' + newReplacement;
  // Filters out dangerous URIs that can create XSS attacks such as `javascript:`.
  const isAbsolute = /^(https?|ftp|file|mailto|tel):/i.test(finalUrl);
  const isRelative = /^\//.test(finalUrl);
  finalUrl = isAbsolute || isRelative ? finalUrl : '';
  // Remove trailing slash
  finalUrl = RemoveTrailingSlashes(finalUrl);
  return finalUrl;
}

export { UrlManager, GetSearchUrl };

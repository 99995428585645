import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isCookiesAllowed } from '../../../utils/helper';
import './index.scss';

function GoogleRecaptcha(props: any) {
  const [isError, setErrorState] = useState(false);
  const [inputHiddenValue, setCaptchaValue] = useState('');
  const [cookieOnPage, setCookieOnPage] = useState(true);
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const IsAutomate = params?.get('uac') === 'automate';
  var langCode = props.sitecoreContext?.language.toLowerCase().substring(0, 2);

  if (typeof window !== 'undefined') {
    useEffect(() => {
      setCookieOnPage(isCookiesAllowed());
    });
  }

  useEffect(() => {
    if (IsAutomate) {
      props.onChange(props.field.valueField.name, 'automate', true, []);
    }
  }, [inputHiddenValue]);

  function onCaptchaChange(value: any): void {
    setCaptchaValue(value);
    if (value === null) {
      setErrorState(true);
    }
    setErrorState(false);
    props.onChange(props.field.valueField.name, value, true, []);
  }

  function fieldServerError() {
    const { errors } = props;
    if ((!errors || errors.length === 0) && !isError) {
      return null;
    }
    // Client side validation
    if (isError) {
      return (
        <div className="invalid">
          <span>{props.field.model.errorMessage}</span>
        </div>
      );
    }

    // Server side validation
    return (
      <div className="invalid">
        {errors.map(
          (
            error:
              | boolean
              | React.ReactChild
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined,
            index: React.Key | null | undefined
          ) => (
            <p key={index}>{error}</p>
          )
        )}
      </div>
    );
  }

  if (IsAutomate) {
    return (
      <>
        <input
          id={props.field.valueField.id}
          name={props.field.valueField.name}
          className="captchaInput"
          type="hidden"
          value="automate"
        />
        {fieldServerError()}
      </>
    );
  }

  return (
    <div className="googleRecaptcha" style={!cookieOnPage ? { display: 'none' } : { display: '' }}>
      <ReCAPTCHA
        sitekey={props.field?.model.publicKey}
        onChange={onCaptchaChange}
        onErrored={() => {
          setErrorState(true);
        }}
        hl={langCode}
      />
      <input
        id={props.field.valueField.id}
        name={props.field.valueField.name}
        className="captchaInput"
        type="hidden"
        value={inputHiddenValue}
      />
      {fieldServerError()}
    </div>
  );
}
export default withSitecoreContext()(GoogleRecaptcha);

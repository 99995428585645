import React, { useEffect } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Tabs from '@material-ui/core/Tabs/Tabs';

import './index.scss';
import {
  buildSearchBox,
  buildResultList,
  buildPager,
  buildQuerySummary,
  buildTab,
  buildQueryExpression,
  buildDidYouMean,
  loadSearchAnalyticsActions,
  loadSearchActions,
  loadAdvancedSearchQueryActions,
} from '@coveo/headless';
import config from '../../../temp/config';
import GeneralContext from '../../../context/general';
import { SearchBox } from '../lib/searchbox';
import { ResultList } from '../lib/resultlist';
import { CoveoTab } from '../lib/tab';
import { Pager } from '../lib/pager';
import { QuerySummary } from '../lib/querysummary';
import { DefaultResult } from '../lib/resulttemplates/defaultresult';
import { UrlManager } from '../lib/utils';
import { DidYouMean } from '../lib/didyoumean';
import HeadlessEngine from '../lib/engine';

type SearchPageProps = {
  fields: {
    Heading: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: any };
};

const headlessEngine = new HeadlessEngine(config.coveoQueryPipeline, 'IrelandSearchHub').engine;

const SearchPage = ({ sitecoreContext }: SearchPageProps): JSX.Element => {
  const searchBox = buildSearchBox(headlessEngine);
  const querySummary = buildQuerySummary(headlessEngine);
  const didYouMean = buildDidYouMean(headlessEngine);
  const resultList = buildResultList(headlessEngine, {
    options: {
      fieldsToInclude: [
        'metaz32xdescription',
        'title',
        'type',
        'z95xtemplatename',
        'z95xfullpath',
        'pagez32xbodyz32xtitle',
      ],
    },
  });
  const pager = buildPager(headlessEngine);

  const alltab = buildTab(headlessEngine, {
    initialState: { isActive: true },
    options: {
      id: 'All Results',
      expression: '',
    },
  });

  const servicestab = buildTab(headlessEngine, {
    options: {
      id: 'Services',
      expression: buildQueryExpression()
        .addStringField({
          field: 'z95xpath',
          operator: 'contains',
          values: ['01d0a79fe15342c1b3e7dec82b4a45bc'],
        })
        .toQuerySyntax(),
    },
  });

  const consultantstab = buildTab(headlessEngine, {
    options: {
      id: 'Consultants',
      expression: buildQueryExpression()
        .addStringField({
          field: 'z95xpath',
          operator: 'contains',
          values: ['8fd222e45b1e4205984bad93d36eed7f'],
        })
        .toQuerySyntax(),
    },
  });

  const newstab = buildTab(headlessEngine, {
    options: {
      id: 'News',
      expression: buildQueryExpression()
        .addStringField({
          field: 'z95xtemplatename',
          operator: 'isExactly',
          values: ['News Page'],
        })
        .toQuerySyntax(),
    },
  });

  function getQueryExpression(sitecoreContext: any) {
    const database = sitecoreContext?.route?.databaseName;
    var currentIndex = 'Coveo_' + database + '_index';
    var siteName = sitecoreContext?.site?.name;
    var sysSource = currentIndex + ' - ' + config.coveoFarmName;
    var currentLanguage = sitecoreContext?.language;
    var languageQuery = '@language==' + currentLanguage;
    var versionQuery = ' @z95xlatestversion==1';
    var siteQuery = ' @site==' + siteName;
    var sourceQuery = ' @syssource=="' + sysSource;
    var templateQuery = '" @z95xtemplatename==("Content Page Base","News Page","Blog Page")';
    var hideFromSearch = ' @hidez32xfromz32xsearch==0';
    var hasLayout = ' @haslayout==1';
    var mediaQuery = '(@z95xfullpath *= "/project/upmc*"' + sourceQuery + '"' + versionQuery + ')';
    var nonMediaQuery =
      '(' +
      languageQuery +
      versionQuery +
      hasLayout +
      siteQuery +
      sourceQuery +
      templateQuery +
      hideFromSearch +
      ')';
    var queryExpression = '(' + nonMediaQuery + ' OR ' + mediaQuery + ')';
    return queryExpression;
  }
  useEffect(() => {
    const { logInterfaceLoad } = loadSearchAnalyticsActions(headlessEngine);
    const { executeSearch } = loadSearchActions(headlessEngine);

    const cqaction = loadAdvancedSearchQueryActions(headlessEngine).registerAdvancedSearchQueries({
      cq: getQueryExpression(sitecoreContext),
    });

    headlessEngine.dispatch(cqaction);
    headlessEngine.dispatch(executeSearch(logInterfaceLoad()));
    UrlManager(headlessEngine);
  });

  return (
    <div className="headless-search-page">
      {sitecoreContext.pageEditing && (
        <>
          <p>SearchPage Component Edit Mode</p>
        </>
      )}
      <div className="search-box-container">
        <SearchBox controller={searchBox} />
        <div className="search-title">
          <QuerySummary controller={querySummary} />
          <DidYouMean controller={didYouMean} />
        </div>
        <div className="search-tab">
          <Tabs aria-label="simple tabs example" variant="scrollable" scrollButtons="on">
            <CoveoTab controller={alltab} title="All" value="0" pagerController={pager}></CoveoTab>
            <CoveoTab
              controller={servicestab}
              title="Services"
              value="1"
              pagerController={pager}
            ></CoveoTab>
            <CoveoTab
              controller={consultantstab}
              title="Consultants"
              value="2"
              pagerController={pager}
            ></CoveoTab>
            <CoveoTab
              controller={newstab}
              title="News"
              value="3"
              pagerController={pager}
            ></CoveoTab>
          </Tabs>
        </div>
        <hr />
        <div className="mainresults-area">
          <ResultList
            controller={resultList}
            hostName={sitecoreContext?.custom?.targetHostName}
            engine={headlessEngine}
          >
            <DefaultResult engine={headlessEngine}></DefaultResult>
          </ResultList>
        </div>
        <div className="footer-area">
          <Pager controller={pager} />
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SearchPage);

import './index.scss';
import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import { simpleUniqueId } from '../../utils/helper';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  Field,
  RichText,
  SitecoreContext,
  withSitecoreContext,
  Placeholder,
  RouteData,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-react';

type AccordionProps = {
  rendering: RouteData | ComponentRendering;
  fields: {
    Heading: Field<string>;
    Opened: Field<boolean>;
    DefaultDetails: Field<string>;
  };
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
  };
};

const Accordion = ({ fields, sitecoreContext, rendering }: AccordionProps): JSX.Element => {
  let heading = fields?.Heading;
  let opened = fields?.Opened;
  let details = fields?.DefaultDetails;
  let accordionController = simpleUniqueId('panel-content-');
  let accordionID = simpleUniqueId('panel-header-').toString();

  if (fields) {
    return (
      <div className="accordion-wrapper">
        {sitecoreContext.pageEditing ? (
          <>
            <p>Accordion Component Edit Mode</p>
            <div className="accordion">
              <MuiAccordion defaultExpanded={true}>
                <MuiAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={accordionController}
                  id={accordionID}
                  className="panel-heading"
                >
                  <Typography>{heading?.value}</Typography>
                </MuiAccordionSummary>
                <MuiAccordionDetails>
                  <Typography>
                    <RichText field={details} />
                  </Typography>
                </MuiAccordionDetails>
                <Placeholder name="page_content" rendering={rendering} />
              </MuiAccordion>
            </div>
          </>
        ) : (
          <div className="accordion">
            <MuiAccordion defaultExpanded={opened?.value}>
              {heading?.value ? (
                <MuiAccordionSummary
                  expandIcon={<ExpandMoreIcon className="expander" />}
                  aria-controls={accordionController}
                  id={accordionID}
                  className="panel"
                >
                  <Typography>{heading?.value}</Typography>
                </MuiAccordionSummary>
              ) : null}
              {details?.value ? (
                <MuiAccordionDetails>
                  <Typography>
                    <RichText field={details} />
                  </Typography>
                </MuiAccordionDetails>
              ) : null}
              <Placeholder name="page_content" rendering={rendering} />
            </MuiAccordion>
          </div>
        )}
      </div>
    );
  } else if (!rendering && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};
export default withSitecoreContext()(Accordion);

import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-react';

interface Day {
  displayName: string;
  id: string;
  fields: {
    Abbreviation: {
      value: string;
    };
  };
}

interface Location {
  fields: {
    ['Location Name']: Field<string>;
  };
}

interface ScheduleItem {
  Day: [Day];
  Location: Location;
}

export interface ScheduleProps {
  fields: {
    Day: [Day];
    Location: Location;
  };
}

export const Schedule = ({ Day, Location }: ScheduleItem): JSX.Element => {
  let numOfDays = Day.length;
  let daysStatement = '';

  if (numOfDays === 1) {
    daysStatement = Day[0].fields.Abbreviation.value;
  } else {
    for (let x = 0; x < numOfDays; x++) {
      if (x + 1 === numOfDays) {
        daysStatement += 'and ' + Day[x].fields.Abbreviation.value;
      } else {
        if (numOfDays === 2) {
          daysStatement += Day[x].fields.Abbreviation.value + ' ';
        } else {
          daysStatement += Day[x].fields.Abbreviation.value + ', ';
        }
      }
    }
  }

  return (
    <>
      <li className="schedule-location-statement">{Location.fields['Location Name'].value}</li>
      <li className="schedule-days-statement">{daysStatement}</li>
    </>
  );
};

export default Schedule;

import React from 'react';
import { ResultList as HeadlessResultList, SearchEngine } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';

interface ResultListProps {
  controller: HeadlessResultList;
  hostName: string;
  engine: SearchEngine;
}

export const ResultList: FunctionComponent<ResultListProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);
  if (!state?.results.length) {
    return (
      <div>
        <p>No results.</p>
        <p>Search tips</p>
        <ul>
          <li>Check the spelling of your keywords.</li>
          <li>Try using fewer, different or more general keywords.</li>
        </ul>
      </div>
    );
  }

  return (
    <div className="result-list">
      {state.results.map((result: any) => (
        <>
          {React.Children.map(props.children, (child: any) => {
            return React.cloneElement(child, {
              controller: controller,
              hostName: props.hostName,
              engine: props.engine,
              result: result,
            });
          })}
        </>
      ))}
    </div>
  );
};

import React, { useEffect, useRef } from 'react';
import {
  RichText,
  Field,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { applyPhoneLinks } from './../../utils/helper';
import './index.scss';

type RichTextEditorProps = {
  fields: {
    Content: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const RichTextEditor = ({ fields, sitecoreContext }: RichTextEditorProps): JSX.Element => {
  const elemRef: any = useRef<HTMLDivElement>(null);
  useEffect(() => {
    applyPhoneLinks(elemRef?.current, sitecoreContext.pageEditing);
  }, []);

  if (fields) {
    return (
      <div ref={elemRef}>
        {sitecoreContext.pageEditing && (
          <>
            <p>RichTextEditor Component Edit Mode</p>
          </>
        )}
        <div className="richtexteditor">
          <RichText field={fields.Content} />
        </div>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(RichTextEditor);

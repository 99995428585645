import React, { useEffect, useRef } from 'react';
import {
  Text,
  Field,
  RichText,
  Image,
  ImageField,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '@material-ui/core';
import Helmet from 'react-helmet';
import { Schedule, ScheduleProps } from './consultant-schedule';
import { applyPhoneLinks } from './../../utils/helper';
import './index.scss';
import { ISpecialty, SpecialtyTitle } from './consultant-specialty';
import { ISubspecialty, SubspecialtyTitle } from './consultant-subspecialty';

type ConsultantDetailsContentProps = {
  fields: {
    Image: ImageField;
    Title: Field<string>;
    'Display Name': Field<string>;
    'First Name': Field<string>;
    'Middle Name': Field<string>;
    'Last Name': Field<string>;
    'Contact Heading': Field<string>;
    'Secretarys Name': Field<string>;
    'Phone Number 1': Field<string>;
    'Phone Number 2': Field<string>;
    'Phone Number 3': Field<string>;
    'Fax Number': Field<string>;
    Email: Field<string>;
    Specialties: [ISpecialty];
    Specialty: Field<string>;
    'Schedules Heading': Field<string>;
    Schedules: [ScheduleProps];
    'Subspecialty Heading': Field<string>;
    Subspecialties: [ISpecialty];
    'Qualifications Heading': Field<string>;
    'Qualifications and Training': Field<string>;
    'Professional Biography Heading': Field<string>;
    'Professional Biography': Field<string>;
    'Research Heading': Field<string>;
    'Research Interest 1': Field<string>;
    'Research Interest 2': Field<string>;
    'Research Interest 3': Field<string>;
    'Professional Membership Heading': Field<string>;
    'Professional Membership 1': Field<string>;
    'Professional Membership 2': Field<string>;
    'Professional Membership 3': Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const ConsultantDetailsContent = ({
  sitecoreContext,
  fields,
}: ConsultantDetailsContentProps): JSX.Element => {
  if (fields) {
    let pathname = typeof window !== 'undefined' ? window.location.href : '';
    // click to call
    const elemRef: any = useRef<HTMLDivElement>(null);
    useEffect(() => {
      applyPhoneLinks(elemRef?.current, sitecoreContext.pageEditing);
    }, []);

    const Person_Schema = JSON.stringify({
      '@context': 'http://schema.org/',
      '@type': 'Person',
      name: `${fields['Display Name'].value}`,
      image: `${fields.Image.value?.src}`,
      url: `${pathname}`,
      knowsAbout: `${fields.Specialty.value}`,
      hasCredential: {
        '@type': 'EducationalOccupationalCredential',
        credentialCategory: `${fields['Qualifications and Training'].value}`,
      },
    });
    return (
      <div className="consultant-details">
        {sitecoreContext.pageEditing && (
          <>
            <p>ConsultantDetails Component Edit Mode</p>
          </>
        )}
        <>
          <Grid container item alignItems="flex-start">
            <Grid item xs={12}>
              <Text tag="h1" className="display-name" field={fields['Display Name']} />

              <div className="consultant-banner-wrapper">
                <div className="consultant-banner-img">
                  <Image className="image img-responsive" field={fields.Image?.value} />
                </div>
                <div className="consultant-banner-details" ref={elemRef}>
                  <div className="consultant-banner-details-innner">
                    {
                      <ul>
                        <SpecialtyTitle fields={fields}></SpecialtyTitle>
                        <Text
                          tag="li"
                          field={fields?.['Contact Heading']}
                          className="consultant-contact-subheading"
                        />
                        <Text tag="li" field={fields['Secretarys Name']} />

                        {fields['Phone Number 1'].value ? (
                          <li>Tel: {fields['Phone Number 1']?.value}</li>
                        ) : (
                          ''
                        )}
                        {fields['Phone Number 2'].value ? (
                          <li>Tel: {fields['Phone Number 2']?.value}</li>
                        ) : null}
                        {fields['Phone Number 3'].value ? (
                          <li>Tel: {fields['Phone Number 3']?.value}</li>
                        ) : null}
                        {fields['Fax Number'].value ? (
                          <li>Fax: {fields['Fax Number']?.value}</li>
                        ) : null}
                        {fields.Email?.value ? (
                          <li>
                            Email: <a href={`mailto:${fields.Email.value}`}>{fields.Email.value}</a>
                          </li>
                        ) : null}
                      </ul>
                    }
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div>
            <div className="consultant-biography">
              <div className="consultant-biography-wrapper">
                {fields?.['Schedules Heading']?.value ? (
                  <ul className="consultant-locations-list">
                    <Text
                      tag="li"
                      className="consultant-schedule-subheading"
                      field={fields?.['Schedules Heading']}
                    />
                    {fields.Schedules.length > 0 ? (
                      fields.Schedules.map((schedule: ScheduleProps, i: number) => (
                        <Schedule
                          key={i}
                          Day={schedule.fields.Day}
                          Location={schedule.fields.Location}
                        />
                      ))
                    ) : sitecoreContext.pageEditing ? (
                      <p className="Content-Author-Alert">
                        Please configure a schedule for the consultant.
                      </p>
                    ) : null}
                  </ul>
                ) : null}

                {fields?.['Subspecialty Heading']?.value ? (
                  <ul className="consultant-subspecialty-list">
                    <Text
                      tag="Span"
                      className="consultant-subspecialty-subheading"
                      field={fields?.['Subspecialty Heading']}
                    />
                    <SubspecialtyTitle fields={fields}></SubspecialtyTitle>
                  </ul>
                ) : null}
                <Text
                  tag="span"
                  className="consultant-qulifications-subheading"
                  field={fields?.['Qualifications Heading']}
                />
                <Text
                  tag="p"
                  className="consultant-qualifications-details"
                  field={fields?.['Qualifications and Training']}
                />
                <Text
                  tag="span"
                  className="consultant-biography-heading"
                  field={fields?.['Professional Biography Heading']}
                />
                <RichText
                  field={fields['Professional Biography']}
                  className="consultant-biography-details"
                />

                <Grid container alignItems="stretch" className="content-row">
                  {fields?.['Research Heading']?.value ? (
                    <Grid item xs={12} md={5}>
                      <div>
                        <ul className="consultant-research-list">
                          <Text
                            tag="li"
                            className="consultant-research-subheading"
                            field={fields?.['Research Heading']}
                          />
                          <Text tag="li" field={fields?.['Research Interest 1']} />
                          <Text tag="li" field={fields?.['Research Interest 2']} />
                          <Text tag="li" field={fields?.['Research Interest 3']} />
                        </ul>
                      </div>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={7}>
                    <div>
                      {fields['Professional Membership Heading']?.value ? (
                        <ul className="consultant-membership-list">
                          <Text
                            tag="li"
                            className="consultant-membership-subheading"
                            field={fields?.['Professional Membership Heading']}
                          />
                          <Text tag="li" field={fields?.['Professional Membership 1']} />
                          <Text tag="li" field={fields?.['Professional Membership 2']} />
                          <Text tag="li" field={fields?.['Professional Membership 3']} />
                        </ul>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>

          <Helmet>
            <script className="structured-data-list" type="application/ld+json">
              {Person_Schema}
            </script>
          </Helmet>
        </>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(ConsultantDetailsContent);

import React from 'react';
import {
  Text,
  Field,
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '@material-ui/core/';

import './index.scss';

type FooterProps = {
  fields: {
    'Search Field Label': Field<string>;
    copyright: Field<string>;
  };
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
};

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <footer>
      <div className="footer-fullwidth-wrapper">
        <div className="footer-top-bar-container">
          <Grid container className="footer-top-bar">
            <Grid item xs={12} md={3}>
              <div className="footer-logo-wrapper">
                <Placeholder name="logo_container" rendering={props.rendering} />
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="footer-search-wrapper">
                <Grid container className="footer-search-wrapper-grid">
                  <Grid item xs={12} md={5}>
                    <Text
                      tag="span"
                      className="footer-search-field-label"
                      field={props?.fields?.['Search Field Label']}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Placeholder name="search_container" rendering={props.rendering} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                <div className="social-icon-wrapper">
                  <Placeholder name="footer_social" rendering={props.rendering} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="footerSiteLinks">
          <Placeholder name="footer_sitemap" rendering={props.rendering} />
        </div>
        <div className="footer-legal-container">
          <Grid container>
            <Grid item xs={12} className="footer-legal-grid">
              <Placeholder name="footer_legal" rendering={props.rendering} />
            </Grid>
            <Grid item xs={12} className="footer-copyright-container">
              <span>
                © {new Date().getFullYear()} <Text field={props.fields.copyright} />
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
